body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  background-color: rgb(253, 253, 253);

 
  justify-content: center;
}
.my-navbar {
  background-color: rgb(0, 118, 148);
  display: flex;
  justify-content: center;
  font-size: 20px;
}

.my-navbar .navbar-collapse {
  display: flex;
  justify-content: center;
}

.my-navbar .navbar-nav .nav-item {
  margin-right: 10px;
}

.my-navbar .navbar-nav .nav-link {
  color: white;
  text-decoration: none;
  padding: 10px;
  transition: background-color 0.3s ease;
  margin-left: 50px;
}

.my-navbar .navbar-nav .nav-link:hover,
.my-navbar .navbar-nav .nav-link:focus {
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  border-color: white;
  border-width: 5px;
}

.my-navbar .navbar-nav .nav-link:hover::before,
.my-navbar .navbar-nav .nav-link:focus::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
}

.my-navbar .navbar-nav .nav-link.active {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
}
.content_class
{
  color: black;
  font-size: 20px;
  display: flex;
  flex-wrap: wrap;
  text-align: justify;
  }
li
{
  font-size: 20px;
}
h2, h4,h5
{
  color:rgb(0, 78, 97);
}
.content-box {
  background-color: #f8f8f8;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  margin: 20px auto;

}

.nav-sub {
  display: block;
  color: rgb(0, 118, 148);
  transition: color 0.3s ease;
  padding: 10px;
  text-decoration: none;
  font-size: 20px;
}

.nav-sub:hover {
  color: black;
}
.error-text
{
  color: red;
  font-size: 15px;
}

.video-container {
  position: relative;
}

.context-menu {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 14px;
  z-index: 9999;
}

.context-menu div {
  padding: 6px 12px;
  cursor: pointer;
}

.context-menu div:hover {
  background-color: #f0f0f0;
}
.form-group
{
  margin-top: 2%;

}
.link_class
{
  background-color: rgb(0, 118, 148);
  color:white; 
  padding:10px;
  border:1px solid black;
  border-radius: 40px;
  text-decoration: none;
  transition: background-color 0.5s ease, color 0.5s ease;
}
.link_class:hover {
  background-color: white !important;
  color: black !important;
  }

  @media (max-width: 575px) {
    .my_col {
      
      margin-top: 15px !important;
    }
  }

  .slider-image {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  
  .contact-info {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .contact-info h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .contact-info ul {
    list-style-type: none;
    padding: 0;
  }
  
  .contact-info li {
    margin-bottom: 10px;
  }
  
  .contact-info li strong {
    font-weight: bold;
  }